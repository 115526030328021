import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(private readonly router: Router, private readonly route: ActivatedRoute, private readonly title: Title) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(map(() => this.route))
      .pipe(
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .subscribe((route) => {
        const routeData = route.snapshot.data;
        this.title.setTitle(
          routeData.title ? routeData.title + ' | ProcedureCard Enterprise' : 'ProcedureCard Enterprise'
        );

        window.scrollTo(0, 0);
      });
  }
}
