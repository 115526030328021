import { Injectable } from '@angular/core';

import { RestService } from './rest.service';

import { environment } from '../../environments/environment';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  public readonly refOrganizationSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private readonly rest: RestService) {}

  getOrganizations(): Promise<any> {
    return this.rest.get(`${environment.apiUrl}/api/organization`);
  }

  getRefOrganization(): Promise<any> {
    return this.rest.get(`${environment.apiUrl}/api/organization/ref`);
  }

  addUser(payload): Promise<any> {
    return this.rest.post(`${environment.apiUrl}/api/organization/user/add`, payload);
  }
}
