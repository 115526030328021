import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
    data: {
      title: 'Login',
    },
  },
  {
    path: 'logout',
    loadChildren: () => import('./logout/logout.module').then((m) => m.LogoutModule),
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./reset-password/reset-password.module').then((m) => m.ResetPasswordModule),
    data: {
      title: 'Reset Password',
    },
  },
  {
    path: 'forgot-username',
    loadChildren: () => import('./forgot-username/forgot-username.module').then((m) => m.ForgotUsernameModule),
    data: {
      title: 'Forgot Username',
    },
  },
  {
    path: 'reset-password-confirmation',
    loadChildren: () =>
      import('./reset-password-confirmation/reset-password-confirmation.module').then(
        (m) => m.ResetPasswordConfirmation
      ),
    data: {
      title: 'Reset Password Confirmation',
    },
  },
  {
    path: 'app',
    loadChildren: () => import('./main/main.module').then((m) => m.MainModule),
  },
  { path: '**', redirectTo: 'logout' },
];

const routerOptions: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64]
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
