import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from './../../environments/environment';

import { ProcedureCopyStatusType, ProcedureEntity } from './../common/types/procedure';

import { AuthService } from './auth.service';
import { RestService } from './rest.service';

export type AutoCompleteItemNameRef  = { name: string, nameRef: string, refNumber: string };
export type AutoCompleteItemsNameRef = AutoCompleteItemNameRef[];

@Injectable({ providedIn: 'root' })
export class ProcedureService {
   constructor(
      private readonly _auth: AuthService,
      private readonly _http: HttpClient,
      private readonly _rest: RestService
   ) { }

   getProcedures(nameFilter: string, providerFilter: number): Promise<any> {
      return this._rest.get(`${environment.apiUrl}/api/procedure?nameFilter=${nameFilter || ""}&providerFilter=${providerFilter || 0}`);
   }

   getProcedureCount(): Promise<number> {
      return this._http.get<number>(`${environment.apiUrl}/api/procedure/number-of-procedures?organizationId=${this._auth.user.organizationId}`).toPromise();
   }

   getProcedureTemplates(): Promise<any> {
      return this._rest.get(`${environment.apiUrl}/api/procedure?type=template`);
   }

   getProcedure(id: number): Promise<any> {
      return this._rest.get(`${environment.apiUrl}/api/procedure/${id}`);
   }

   getProcedureTemplate(id: number): Promise<any> {
      return this._rest.get(`${environment.apiUrl}/api/procedure/${id}?type=template`);
   }

   createProcedure(payload): Promise<any> {
      return this._rest.post(`${environment.apiUrl}/api/procedure`, payload);
   }

   createProcedureFromTemplate(payload): Promise<any> {
      return this._rest.post(`${environment.apiUrl}/api/procedure?ref=template`, payload);
   }

   saveProcedure(id: number, payload): Promise<any> {
      return this._rest.put(`${environment.apiUrl}/api/procedure/${id}`, payload);
   }

   logRevisions(id: number, payload): Promise<any> {
      return this._rest.post(`${environment.apiUrl}/api/procedure/${id}/revision-log`, payload);
   }

   deleteProcedure(id: number): Promise<any> {
      return this._rest.delete(`${environment.apiUrl}/api/procedure/${id}`);
   }

   downloadPdf(id: number): Promise<any> {
      return this._rest.post(`${environment.apiUrl}/api/procedure-pdf/${id}`, {});
   }

   downloadProcedureCopyPdf(procedureCopyId: number): Promise<any> {
      return this._rest.post(`${environment.apiUrl}/api/procedure-pdf/procedure-copy/${procedureCopyId}`, {});
   }

   getAutocompleteItems(procedureEntity: ProcedureEntity): Promise<AutoCompleteItemsNameRef> {
      return this._rest // get distinct name and ref numbers
         .get(`${environment.apiUrl}/api/procedure/autocomplete?type=${procedureEntity}`) // TODO: make "get" generic if we don't rewrite the project
         .then<AutoCompleteItemsNameRef>(response => ( Boolean(response?.data)
            ? response.data.map(item => ({ name: item.name, refNumber: item.reference_number }))
            : []
         ))
         .then(response => response.filter(item => Boolean(item.name)))
         .then(response => response.map(item => ({
            refNumber : item.refNumber,
            name      : item.name,
            nameRef   : item.name + ( item.refNumber ? (` (ref: ${item.refNumber})`) : "" )
         })));
   }

   createProcedureCopy(procedureId: string): Promise<any> {
      return this._rest.post(`${environment.apiUrl}/api/procedure/create-procedure-copy/${procedureId}`, {});
   }

   getAllProcedureCopies(nameFilter: string, providerFilter: number, statusFilter: string, dateFilter: number, startDate: string, endDate: string): Promise<any> {
      return this._rest.get(`${environment.apiUrl}/api/procedure/get-procedure-copies?nameFilter=${nameFilter || ""}&providerFilter=${providerFilter || 0}&statusFilter=${statusFilter}&dateFilter=${dateFilter}&startDate=${startDate}&endDate=${endDate}`);
   }

   getProcedureCopy(id: number): Promise<any> {
      return this._rest.get(`${environment.apiUrl}/api/procedure/get-procedure-copies/${id}`);
   }

   updateProcedureCopy(procedureCopyId: number, payload: any): Promise<any> {
      return this._rest.put(`${environment.apiUrl}/api/procedure/update-procedure-copy/${procedureCopyId}`, payload);
   }
}
